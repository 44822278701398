exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-2-p-10-dlc-guide-tsx": () => import("./../../../src/pages/a2p-10dlc-guide.tsx" /* webpackChunkName: "component---src-pages-a-2-p-10-dlc-guide-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-carrier-fees-tsx": () => import("./../../../src/pages/carrier-fees.tsx" /* webpackChunkName: "component---src-pages-carrier-fees-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-stories-bonzo-tsx": () => import("./../../../src/pages/customer-stories/bonzo.tsx" /* webpackChunkName: "component---src-pages-customer-stories-bonzo-tsx" */),
  "component---src-pages-customer-stories-boulevard-tsx": () => import("./../../../src/pages/customer-stories/boulevard.tsx" /* webpackChunkName: "component---src-pages-customer-stories-boulevard-tsx" */),
  "component---src-pages-customer-stories-onlysales-tsx": () => import("./../../../src/pages/customer-stories/onlysales.tsx" /* webpackChunkName: "component---src-pages-customer-stories-onlysales-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-customer-support-tsx": () => import("./../../../src/pages/customer-support.tsx" /* webpackChunkName: "component---src-pages-customer-support-tsx" */),
  "component---src-pages-get-demo-tsx": () => import("./../../../src/pages/get-demo.tsx" /* webpackChunkName: "component---src-pages-get-demo-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-payg-trial-tsx": () => import("./../../../src/pages/payg-trial.tsx" /* webpackChunkName: "component---src-pages-payg-trial-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-smart-queueing-tsx": () => import("./../../../src/pages/smart-queueing.tsx" /* webpackChunkName: "component---src-pages-smart-queueing-tsx" */),
  "component---src-pages-telgorithm-vs-bandwidth-tsx": () => import("./../../../src/pages/telgorithm-vs-bandwidth.tsx" /* webpackChunkName: "component---src-pages-telgorithm-vs-bandwidth-tsx" */),
  "component---src-pages-telgorithm-vs-telnyx-tsx": () => import("./../../../src/pages/telgorithm-vs-telnyx.tsx" /* webpackChunkName: "component---src-pages-telgorithm-vs-telnyx-tsx" */),
  "component---src-pages-telgorithm-vs-twilio-tsx": () => import("./../../../src/pages/telgorithm-vs-twilio.tsx" /* webpackChunkName: "component---src-pages-telgorithm-vs-twilio-tsx" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-campaign-vetting-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-campaign-vetting/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-campaign-vetting-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-compliance-checklist-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-compliance-checklist/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-compliance-checklist-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-compliance-faqs-answered-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-compliance-faqs-answered/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-compliance-faqs-answered-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-compliance-syniverse-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-compliance-syniverse/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-compliance-syniverse-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-customer-experience-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-customer-experience/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-customer-experience-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-cut-off-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-cut-off/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-cut-off-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-10-dlc-registration-required-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/10dlc-registration-required/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-10-dlc-registration-required-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-2024-carrier-fee-increases-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/2024-carrier-fee-increases/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-2024-carrier-fee-increases-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-a-2-p-10-dlc-rate-limits-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/a2p-10dlc-rate-limits/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-a-2-p-10-dlc-rate-limits-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-ankur-passi-telgorithm-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/ankur-passi-telgorithm/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-ankur-passi-telgorithm-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-api-integration-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/api-integration/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-api-integration-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-api-integration-migration-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/api-integration-migration/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-api-integration-migration-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-at-t-to-increase-a-2-p-10-dlc-pass-through-fees-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/at-t-to-increase-a2p-10dlc-pass-through-fees/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-at-t-to-increase-a-2-p-10-dlc-pass-through-fees-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-att-oct-1-2024-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/att-oct-1-2024/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-att-oct-1-2024-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-automated-number-porting-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/automated-number-porting/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-automated-number-porting-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-avoid-a-2-p-10-dlc-registration-and-vetting-delays-with-telgorithm-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/avoid-a2p-10dlc-registration-and-vetting-delays-with-telgorithm/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-avoid-a-2-p-10-dlc-registration-and-vetting-delays-with-telgorithm-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-bonfire-ventures-why-we-invested-in-telgorithms-tailor-made-solution-for-vertical-and-smb-saas-vendors-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/bonfire-ventures-why-we-invested-in-telgorithm’s-tailor-made-solution-for-vertical-and-smb-saas-vendors/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-bonfire-ventures-why-we-invested-in-telgorithms-tailor-made-solution-for-vertical-and-smb-saas-vendors-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-bonzo-interview-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/bonzo-interview/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-bonzo-interview-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-brand-campaign-rejections-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/brand-campaign-rejections/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-brand-campaign-rejections-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-bulk-texting-for-business-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/bulk-texting-for-business/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-bulk-texting-for-business-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-campaign-service-provider-vs-reseller-whats-the-difference-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/campaign-service-provider-vs-reseller-what’s-the-difference/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-campaign-service-provider-vs-reseller-whats-the-difference-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-campaign-types-and-what-they-mean-for-your-throughput-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/campaign-types-and-what-they-mean-for-your-throughput/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-campaign-types-and-what-they-mean-for-your-throughput-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-case-study-telgorithms-journey-towards-product-market-fit-involves-celebrating-your-successes-and-losses-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/case-study-telgorithms-journey-towards-product-market-fit-involves-celebrating-your-successes-and-losses/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-case-study-telgorithms-journey-towards-product-market-fit-involves-celebrating-your-successes-and-losses-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-cnp-migration-tool-for-10-dlc-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/cnp-migration-tool-for-10dlc/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-cnp-migration-tool-for-10-dlc-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-constant-contact-telgorithm-partnership-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/constant-contact-telgorithm-partnership/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-constant-contact-telgorithm-partnership-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-cpaas-sms-mms-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/cpaas-sms-mms/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-cpaas-sms-mms-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-dca-or-api-provider-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/dca-or-api-provider/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-dca-or-api-provider-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-experiencing-blocked-a-2-p-sms-messages-on-t-mobile-this-could-be-why-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/experiencing-blocked-a2p-sms-messages-on-t-mobile-this-could-be-why/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-experiencing-blocked-a-2-p-sms-messages-on-t-mobile-this-could-be-why-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-food-for-thought-what-are-you-paying-for-when-you-register-with-the-campaign-registry-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/food-for-thought-what-are-you-paying-for-when-you-register-with-the-campaign-registry/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-food-for-thought-what-are-you-paying-for-when-you-register-with-the-campaign-registry-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-forbes-telgorithm-raises-3-8-m-to-become-the-one-stop-shop-for-all-your-cloud-communication-needs-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/forbes-telgorithm-raises-3-8m-to-become-the-one-stop-shop-for-all-your-cloud-communication-needs/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-forbes-telgorithm-raises-3-8-m-to-become-the-one-stop-shop-for-all-your-cloud-communication-needs-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-guide-to-csp-portal-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/guide-to-csp-portal/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-guide-to-csp-portal-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-hosted-messaging-provider-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/hosted-messaging-provider/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-hosted-messaging-provider-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-how-telgorithm-streamlines-tcr-10-dlc-registration-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/how-telgorithm-streamlines-tcr-10dlc-registration/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-how-telgorithm-streamlines-tcr-10-dlc-registration-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-how-to-choose-an-a-2-p-10-dlc-provider-step-by-step-guide-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/how-to-choose-an-a2p-10dlc-provider-step-by-step-guide/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-how-to-choose-an-a-2-p-10-dlc-provider-step-by-step-guide-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-how-to-choose-the-best-tcr-campaign-for-your-business-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/how-to-choose-the-best-tcr-campaign-for-your-business/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-how-to-choose-the-best-tcr-campaign-for-your-business-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-how-to-register-as-csp-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/how-to-register-as-csp/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-how-to-register-as-csp-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-how-your-sms-throughput-fate-is-decided-for-a-2-p-10-dlc-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/how-your-sms-throughput-fate-is-decided-for-a2p-10dlc/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-how-your-sms-throughput-fate-is-decided-for-a-2-p-10-dlc-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-leaders-in-10-dlc-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/leaders-in-10dlc/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-leaders-in-10-dlc-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-message-deliverability-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/message-deliverability/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-message-deliverability-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-multithreading-api-providers-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/multithreading-api-providers/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-multithreading-api-providers-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-new-campaign-guidelines-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/new-campaign-guidelines/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-new-campaign-guidelines-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-number-verification-how-sending-a-2-p-texts-to-bad-numbers-can-impact-your-bottom-line-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/number-verification-how-sending-a2p-texts-to-bad-numbers-can-impact-your-bottom-line/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-number-verification-how-sending-a-2-p-texts-to-bad-numbers-can-impact-your-bottom-line-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-onlysales-interview-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/onlysales-interview/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-onlysales-interview-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-programmable-sms-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/programmable-sms/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-programmable-sms-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-secondary-campaign-vetting-2024-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/secondary-campaign-vetting-2024/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-secondary-campaign-vetting-2024-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-seeing-is-believing-demo-telgorithms-expedited-10-dlc-campaign-vetting-approvals-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/seeing-is-believing-demo-telgorithm’s-expedited-10dlc-campaign-vetting-approvals/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-seeing-is-believing-demo-telgorithms-expedited-10-dlc-campaign-vetting-approvals-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-short-code-vs-local-vs-toll-free-number-which-should-i-use-and-why-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/short-code-vs-local-vs-toll-free-number-which-should-i-use-and-why/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-short-code-vs-local-vs-toll-free-number-which-should-i-use-and-why-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-smart-queueing-and-why-its-a-necessity-for-a-2-p-messaging-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/smart-queueing-and-why-it’s-a-necessity-for-a2p-messaging/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-smart-queueing-and-why-its-a-necessity-for-a-2-p-messaging-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-sole-prop-2-0-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/sole-prop-2-0/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-sole-prop-2-0-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-sole-prop-dec-1-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/sole-prop-dec-1/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-sole-prop-dec-1-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-sole-prop-registration-guide-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/sole-prop-registration-guide/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-sole-prop-registration-guide-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-speed-up-registration-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/speed-up-registration/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-speed-up-registration-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-switching-messaging-api-providers-just-got-a-little-easier-thanks-to-t-mobiles-recent-announcement-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/switching-messaging-api-providers-just-got-a-little-easier-thanks-to-t-mobile’s-recent-announcement/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-switching-messaging-api-providers-just-got-a-little-easier-thanks-to-t-mobiles-recent-announcement-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-syniverse-deadline-extension-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/syniverse-deadline-extension/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-syniverse-deadline-extension-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-syniverse-unregistered-10-dlc-cutoff-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/syniverse-unregistered-10dlc-cutoff/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-syniverse-unregistered-10-dlc-cutoff-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-t-mobiles-non-use-fee-for-a-2-p-10-dlc-campaigns-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/t-mobile’s-non-use-fee-for-a2p-10dlc-campaigns/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-t-mobiles-non-use-fee-for-a-2-p-10-dlc-campaigns-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-tcr-registration-campaign-service-provider-or-reseller-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/tcr-registration-campaign-service-provider-or-reseller/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-tcr-registration-campaign-service-provider-or-reseller-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-telgorithm-is-the-best-replacement-for-your-zipwhip-messaging-apis-heres-why-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/telgorithm-is-the-best-replacement-for-your-zipwhip-messaging-apis-–-here’s-why/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-telgorithm-is-the-best-replacement-for-your-zipwhip-messaging-apis-heres-why-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-telgorithm-raises-3-8-million-to-expand-compliance-based-messaging-platform-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/telgorithm-raises-3-8-million-to-expand-compliance-based-messaging-platform/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-telgorithm-raises-3-8-million-to-expand-compliance-based-messaging-platform-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-telgorithms-take-rcs-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/telgorithms-take-rcs/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-telgorithms-take-rcs-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-textspot-interview-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/textspot-interview/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-textspot-interview-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-the-perks-of-being-registered-with-the-campaign-registry-as-a-campaign-service-provider-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/the-perks-of-being-registered-with-the-campaign-registry-as-a-campaign-service-provider/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-the-perks-of-being-registered-with-the-campaign-registry-as-a-campaign-service-provider-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-time-routing-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/time-routing/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-time-routing-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-tips-for-cutting-costs-and-maxing-out-a-2-p-texting-roi-in-2023-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/tips-for-cutting-costs-and-maxing-out-a2p-texting-roi-in-2023/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-tips-for-cutting-costs-and-maxing-out-a-2-p-texting-roi-in-2023-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-tmobile-increase-june-2024-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/tmobile-increase-june-2024/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-tmobile-increase-june-2024-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-tmobile-to-increase-pass-through-fees-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/tmobile-to-increase-pass-through-fees/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-tmobile-to-increase-pass-through-fees-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-toll-free-messaging-a-2-p-dlc-the-2022-dream-team-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/toll-free-messaging-a2p-dlc-the-2022-dream-team/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-toll-free-messaging-a-2-p-dlc-the-2022-dream-team-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-toll-free-verification-is-no-longer-optional-heres-what-you-need-to-know-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/toll-free-verification-is-no-longer-optional-here’s-what-you-need-to-know/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-toll-free-verification-is-no-longer-optional-heres-what-you-need-to-know-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-toll-free-vs-10-dlc-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/toll-free-vs-10dlc/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-toll-free-vs-10-dlc-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-twilio-alternative-sms-api-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/twilio-alternative-sms-api/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-twilio-alternative-sms-api-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-understanding-a-2-p-text-message-capping-vs-queueing-is-easy-as-pie-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/understanding-a2p-text-message-capping-vs-queueing-is-easy-as-pie/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-understanding-a-2-p-text-message-capping-vs-queueing-is-easy-as-pie-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-verizon-wireless-and-the-great-a-2-p-10-dlc-rollout-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/verizon-wireless-and-the-great-a2p-10dlc-rollout/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-verizon-wireless-and-the-great-a-2-p-10-dlc-rollout-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-what-is-a-2-p-10-dlc-and-why-does-it-matter-in-2022-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/what-is-a2p-10dlc-and-why-does-it-matter-in-2022/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-what-is-a-2-p-10-dlc-and-why-does-it-matter-in-2022-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-why-csps-switch-to-telgorithm-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/why-csps-switch-to-telgorithm/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-why-csps-switch-to-telgorithm-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-why-every-saas-company-should-multithread-messaging-service-providers-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/why-every-saas-company-should-multithread-messaging-service-providers/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-why-every-saas-company-should-multithread-messaging-service-providers-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-why-your-unregistered-sms-traffic-is-giving-you-issues-and-how-to-solve-with-long-code-registration-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/why-your-unregistered-sms-traffic-is-giving-you-issues-and-how-to-solve-with-long-code-registration/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-why-your-unregistered-sms-traffic-is-giving-you-issues-and-how-to-solve-with-long-code-registration-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-your-a-2-p-10-dlc-texting-campaigns-are-probably-being-capped-or-blocked-heres-why-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/your-a2p-10dlc-texting-campaigns-are-probably-being-capped-or-blocked-–-here’s-why/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-your-a-2-p-10-dlc-texting-campaigns-are-probably-being-capped-or-blocked-heres-why-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-your-sms-messages-are-likely-being-capped-by-your-messaging-api-provider-and-why-you-should-care-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/your-sms-messages-are-likely-being-capped-by-your-messaging-api-provider-and-why-you-should-care/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-your-sms-messages-are-likely-being-capped-by-your-messaging-api-provider-and-why-you-should-care-index-md" */),
  "component---src-templates-article-tsx-content-file-path-news-zipwhip-warns-of-immediate-message-blockages-for-unregistered-10-dlc-traffic-index-md": () => import("./../../../src/templates/article.tsx?__contentFilePath=/home/runner/work/landing/landing/news/zipwhip-warns-of-immediate-message-blockages-for-unregistered-10dlc-traffic/index.md" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-news-zipwhip-warns-of-immediate-message-blockages-for-unregistered-10-dlc-traffic-index-md" */),
  "component---src-templates-bio-tsx-content-file-path-bios-aaron-alter-index-md": () => import("./../../../src/templates/bio.tsx?__contentFilePath=/home/runner/work/landing/landing/bios/aaron-alter/index.md" /* webpackChunkName: "component---src-templates-bio-tsx-content-file-path-bios-aaron-alter-index-md" */),
  "component---src-templates-bio-tsx-content-file-path-bios-yury-semerikov-index-md": () => import("./../../../src/templates/bio.tsx?__contentFilePath=/home/runner/work/landing/landing/bios/yury-semerikov/index.md" /* webpackChunkName: "component---src-templates-bio-tsx-content-file-path-bios-yury-semerikov-index-md" */),
  "component---src-templates-policy-tsx-content-file-path-policies-acceptable-use-policy-index-md": () => import("./../../../src/templates/policy.tsx?__contentFilePath=/home/runner/work/landing/landing/policies/acceptable-use-policy/index.md" /* webpackChunkName: "component---src-templates-policy-tsx-content-file-path-policies-acceptable-use-policy-index-md" */),
  "component---src-templates-policy-tsx-content-file-path-policies-privacy-policy-index-md": () => import("./../../../src/templates/policy.tsx?__contentFilePath=/home/runner/work/landing/landing/policies/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-policy-tsx-content-file-path-policies-privacy-policy-index-md" */),
  "component---src-templates-policy-tsx-content-file-path-policies-terms-and-conditions-index-md": () => import("./../../../src/templates/policy.tsx?__contentFilePath=/home/runner/work/landing/landing/policies/terms-and-conditions/index.md" /* webpackChunkName: "component---src-templates-policy-tsx-content-file-path-policies-terms-and-conditions-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-10-digit-long-code-10-dlc-numbers-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/10-digit-long-code-10dlc-numbers/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-10-digit-long-code-10-dlc-numbers-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-10-dlc-campaign-rejection-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/10dlc-campaign-rejection/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-10-dlc-campaign-rejection-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-10-dlc-compliance-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/10dlc-compliance/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-10-dlc-compliance-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-a-2-p-messaging-disallowed-content-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/a2p-messaging-disallowed-content/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-a-2-p-messaging-disallowed-content-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-application-to-person-messaging-a-2-p-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/application-to-person-messaging-a2p/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-application-to-person-messaging-a-2-p-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-automated-number-porting-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/automated-number-porting/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-automated-number-porting-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-automated-tcr-registration-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/automated-tcr-registration/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-automated-tcr-registration-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-brand-and-campaign-rejections-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/brand-and-campaign-rejections/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-brand-and-campaign-rejections-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-brand-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/brand/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-brand-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-campaign-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/campaign/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-campaign-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-campaign-service-provider-csp-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/campaign-service-provider-csp/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-campaign-service-provider-csp-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-capping-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/capping/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-capping-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-connectivity-provider-cnp-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/connectivity-provider-cnp/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-connectivity-provider-cnp-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-direct-connect-aggregators-dca-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/direct-connect-aggregators-dca/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-direct-connect-aggregators-dca-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-hosted-sms-messaging-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/hosted-sms-messaging/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-hosted-sms-messaging-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-local-numbers-10-dlc-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/local-numbers-10dlc/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-local-numbers-10-dlc-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-multithread-multithreading-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/multithread-multithreading/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-multithread-multithreading-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-number-verification-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/number-verification/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-number-verification-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-person-to-person-messaging-p-2-p-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/person-to-person-messaging-p2p/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-person-to-person-messaging-p-2-p-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-reseller-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/reseller/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-reseller-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-smart-queueing-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/smart-queueing/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-smart-queueing-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-snowshoeing-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/snowshoeing/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-snowshoeing-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-sole-proprietor-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/sole-proprietor/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-sole-proprietor-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-the-campaign-registry-tcr-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/the-campaign-registry-tcr/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-the-campaign-registry-tcr-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-throughput-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/throughput/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-throughput-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-throughput-limit-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/throughput-limit/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-throughput-limit-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-time-routing-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/time-routing/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-time-routing-index-md" */),
  "component---src-templates-term-tsx-content-file-path-terms-toll-free-numbers-index-md": () => import("./../../../src/templates/term.tsx?__contentFilePath=/home/runner/work/landing/landing/terms/toll-free-numbers/index.md" /* webpackChunkName: "component---src-templates-term-tsx-content-file-path-terms-toll-free-numbers-index-md" */)
}

